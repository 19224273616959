import Vue from "vue";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";

Vue.use(VXETable);

VXETable.setup({
  size: "mini", // 全局尺寸
  zIndex: 100, // 全局 zIndex 起始值，如果项目的的 z-index 样式值过大时就需要跟随设置更大，避免被遮挡
  version: 0, // 版本号，对于某些带数据缓存的功能有用到，上升版本号可以用于重置数据
  table: {
    // showHeader: true,
    // keepSource: false,
    // showOverflow: true,
    // showHeaderOverflow: true,
    // showFooterOverflow: true,
    highlightHoverRow: true,
    highlightCurrentRow: true,
    // size: "mini",
    resizable: true,
    stripe: true,
    border: true,
    optimization: {
      // animat: true,
      // cloak: false,
      // delayHover: 250,
      scrollX: {
        gt: 60,
      },
      scrollY: {
        gt: 100,
      },
    },
    rowId: "id", // 行数据的唯一主键字段名
  },
  grid: {
    resizable: false,
    // size: "mini",
    proxyConfig: {
      autoLoad: true,
      message: true,
      props: {
        list: null, // 用于列表，读取响应数据
        result: "data", // 用于分页，读取响应数据
        total: "count", // 用于分页，读取总条数
      },
    },
    pagerConfig: {
      perfect: true,
      pageSize: 50,
      pageSizes: [50, 100, 200, 500, 1000],
      layouts: ["PrevJump", "PrevPage", "Jump", "PageCount", "NextPage", "NextJump", "Sizes", "Total"],
    },
  },
  // pager: {
  //   perfect: true,
  //   pageSize: 50,
  //   pagerCount: 10,
  //   pageSizes: [50, 100, 200, 500, 1000],
  //   layouts: ["PrevJump", "PrevPage", "Jump", "PageCount", "NextPage", "NextJump", "Sizes", "Total"]
  // }
});
