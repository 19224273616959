export default [
  {
    path: "Home",
    meta: {
      title: "首页",
      name: "Home",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Home/index"),
  },
  {
    path: "/redirect/:path*",
    component: () => import("@/views/Common/Redirect.vue"),
  },
  // {
  //   path: "Quote",
  //   meta: {
  //     title: "加工询价",
  //     name: "Quote",
  //   },
  //   component: () => import(/* webpackChunkName: "group-Home" */ "@/views/IFrame/index"),
  //   props: {
  //     href: "https://www.imouldyun.com/PC/ProcessQuote/factory",
  //   },
  // },
  // {
  //   path: "JGKD",
  //   meta: {
  //     title: "加工开单",
  //     name: "JGKD",
  //   },
  //   component: () => import(/* webpackChunkName: "group-Home" */ "@/views/IFrame/index"),
  //   props: {
  //     href: "https://www.imouldyun.com/Home/Show?name=../MES/newJGKD",
  //   },
  // },
  // {
  //   path: "ProcessTask",
  //   meta: {
  //     title: "生产任务池",
  //     name: "ProcessTask",
  //   },
  //   component: () => import(/* webpackChunkName: "group-Home" */ "@/views/ProcessTask/index"),

  //   props: (route) => {
  //     return { auth: route.meta.auth, href: route.meta.href, name: route.meta.name, subName: route.meta.subName };
  //   },
  //   redirect: "ProcessTask/OutterProcessTask",
  //   children: [
  //     {
  //       path: "OutterProcessTask",
  //       meta: {
  //         title: "外协任务",
  //         name: "ProcessTask",
  //         subName: "OutterProcessTask",
  //         auth: "Menu.MES.Tenancy.OutterProcessTask",
  //       },
  //     },
  //     {
  //       path: "InnerProcessTask",
  //       meta: {
  //         title: "厂内任务",
  //         name: "ProcessTask",
  //         subName: "InnerProcessTask",
  //         auth: "Menu.MES.Tenancy.InnerTaskForOutter",
  //       },
  //     },
  //     {
  //       path: "OutInquiryProcessTask",
  //       meta: {
  //         title: "待定点任务",
  //         name: "ProcessTask",
  //         subName: "OutInquiryProcessTask",
  //         auth: "Menu.MES.Tenancy.OutInquiryProcessTask",
  //       },
  //     },
  //     {
  //       path: "OutInquiryNoSN",
  //       meta: {
  //         title: "已定点未开单",
  //         name: "ProcessTask",
  //         subName: "OutInquiryNoSN",
  //         auth: "Menu.MES.Tenancy.OutInquiryNoSN",
  //       },
  //     },
  //     {
  //       path: "OutProcessProcessTask",
  //       meta: {
  //         title: "待完工任务",
  //         name: "ProcessTask",
  //         subName: "OutProcessProcessTask",
  //         auth: "Menu.MES.Tenancy.OutProcessProcessTask",
  //       },
  //     },
  //     {
  //       path: "OutProcessNoReturn",
  //       meta: {
  //         title: "未返厂任务",
  //         name: "ProcessTask",
  //         subName: "OutProcessNoReturn",
  //         auth: "Menu.MES.Tenancy.OutProcessNoReturn",
  //       },
  //     },
  //     {
  //       path: "OutFeeProcessTask",
  //       meta: {
  //         title: "待回单任务",
  //         name: "ProcessTask",
  //         subName: "OutFeeProcessTask",
  //         auth: "Menu.MES.Tenancy.OutFeeProcessTask",
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: "Finance",
  //   meta: {
  //     title: "财务",
  //     name: "Finance",
  //   },
  //   props: (route) => {
  //     console.log(route, "finance");
  //     return { auth: route.meta.auth, href: route.meta.href, name: route.meta.name, subName: route.meta.subName };
  //   },
  //   redirect: "/Finance/SummaryReport",
  //   component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Finance/index"),
  //   children: [
  //     {
  //       path: "ProcessCost",
  //       meta: {
  //         title: "加工核算",
  //         name: "Finance",
  //         subName: "ProcessCost",
  //       },
  //     },
  //     {
  //       path: "Cancellation",
  //       meta: {
  //         title: "往来核销",
  //         name: "Finance",
  //         subName: "Cancellation",
  //       },
  //     },
  //     {
  //       path: "SummaryReport",
  //       meta: {
  //         title: "成本分析",
  //         name: "Finance",
  //         subName: "SummaryReport",
  //       },
  //     },
  //   ],
  // },

  // {
  //   path: "Resource",
  //   meta: {
  //     title: "企业资源",
  //     name: "Resource",
  //   },
  //   component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Resource/index"),
  // },
  // {
  //   path: "Standard",
  //   meta: {
  //     title: "标准",
  //     name: "Standard",
  //   },
  //   component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Standard/index"),
  //   props: {
  //     href: "https://www.imouldyun.com/Standard",
  //   },
  // },
];
