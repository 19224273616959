import { defineStore } from "pinia";
import { isMobile } from "@/utils/util";
export const useMobileStore = defineStore("mobile", {
  state: () => ({
    isMobile: isMobile(),
  }),
  actions: {
    setMobile() {
      this.isMobile = isMobile();
    },
  },
});
