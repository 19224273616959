<!--  -->
<template>
  <iframe ref="iframe" :src="comSrc" frameborder="0" class="layadmin-iframe"></iframe>
</template>

<script>
import { getUrl } from "@/core/utils";
export default {
  name: "RIframe",
  props: {
    url: String,
    src: String,
  },
  computed: {
    comSrc() {
      return getUrl(this.src || this.url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
