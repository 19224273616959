<!--  -->
<template>
  <div>
    <el-upload
      action=""
      class="avatar-uploader"
      :auto-upload="false"
      accept="image/*"
      :on-change="getUploadFile"
      :show-file-list="false"
    >
      <logo-avatar :src="value" :name="name" :radius="radius" :size="size"></logo-avatar>
      <div style="color: #7e7e7e">建议:180*180，支持:png, gif, jpg</div>
    </el-upload>
    <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body>
      <div class="cropper-content">
        <div class="cropper" style="text-align: center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :fixed="option.fixed"
            :fixed-number="option.fixedNumber"
            :center-box="option.centerBox"
            :info-true="option.infoTrue"
          ></vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="finish">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadFileAntd } from "@/utils/tool";
export default {
  props: {
    value: String,
    name: String,
    radius: [String, Number],
    size: [String, Number],
  },
  data() {
    return {
      dialogVisible: false,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      //   fileinfo: null,
    };
  },
  methods: {
    // Method函数
    getUploadFile(file) {
      var files = file.raw;
      this.fileinfo = file; // 保存下当前文件的一些基本信息
      let reader = new FileReader(); // 创建文件读取对象
      reader.onload = async (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data; // 设置option的初始image
        this.dialogVisible = true;
      };
      reader.readAsArrayBuffer(files);
      this.option.fixedNumber = [1, 1]; // 图片的裁剪宽高比在这里也可以进行设置
    },
    /*
     * 将上传添加到这里来执行
     */
    finish() {
      this.$refs.cropper.getCropBlob(async (data) => {
        // 获取当前裁剪好的数据
        // 注此时的data是一个Blob数据，部分接口接收的是File转化的FormData数据
        let formData = new FormData();
        formData.append(
          "file",
          new File(
            [data], // 将Blob类型转化成File类型
            this.fileinfo.name, // 设置File类型的文件名称
            { type: this.fileinfo.type } // 设置File类型的文件类型
          )
        );
        let file = new File(
          [data], // 将Blob类型转化成File类型
          this.fileinfo.name, // 设置File类型的文件名称
          { type: this.fileinfo.type } // 设置File类型的文件类型
        );
        uploadFileAntd({ file, onProgress: () => {}, onSuccess: () => {}, onError: () => {} }, this.fileinfo.name).then(
          (res) => {
            this.dialogVisible = false;
            this.$emit("input", res.url);
          }
        );

        // let res = await this.$ajax.post("/api/cloudstorage/cloudfile/uploadfiles", formData);
        // console.log(res);

        // 调用接口上传
        // upLoadFile(formData).then((result) => {
        //   console.log(result);
        //   this.dialogVisible = false;
        // });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//设置裁剪框样式
.cropper-content {
  .cropper {
    width: auto;
    height: 200px;
  }
}
</style>
