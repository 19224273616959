import { _ } from "core-js";

//  判断是否为移动端
export function isMobile() {
  let isMobile = false;
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    // console.log('移动端');
    isMobile = true;
  }
  if (document.body.clientWidth < 800) {
    isMobile = true;
  }
  return isMobile;
}
/**
 * 防抖
 * @param {function} method 要运行的函数
 * @param {number} wait 等待时间 ms
 * @param {boolean } immediate 立即
 * @returns {function}
 */
export function debounce(method, wait = 300, immediate = false) {
  let timeout, result;
  let debounced = function (...args) {
    var isPromise = (a) => a instanceof Promise;
    // 返回一个Promise，以便可以使用then或者Async/Await语法拿到原函数返回值
    return new Promise((resolve) => {
      let context = this;
      if (timeout) {
        clearTimeout(timeout);
      }
      if (immediate) {
        let callNow = !timeout;
        timeout = setTimeout(() => {
          timeout = null;
        }, wait);
        if (callNow) {
          result = method.apply(context, args);
          // 将原函数的返回值传给resolve
          if (isPromise(result)) {
            result.then((res) => resolve(res));
          } else {
            resolve(result);
          }
        }
      } else {
        timeout = setTimeout(() => {
          result = method.apply(context, args);
          // 将原函数的返回值传给resolve
          if (isPromise(result)) {
            result.then((res) => resolve(res));
          } else {
            resolve(result);
          }
        }, wait);
      }
    });
  };

  debounced.cancel = function () {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
}

/**
 * @Description: join param to url
 * @param {Object} params
 * @return {String} url
 */
export function joinParams(url, params) {
  let editFlag = false;
  if (url.indexOf("?") === -1) {
    url += "?";
  }
  for (let key in params) {
    if (params[key] !== undefined) {
      let _text =
        typeof params[key] === "object"
          ? JSON.stringify(params[key]) === "{}"
            ? null
            : JSON.stringify(params[key])
          : params[key];
      if (_text) {
        url += `${key}=${_text}&`;
        editFlag = true;
      }
    }
  }
  return editFlag ? url.substring(0, url.length - 1) : url;
}
