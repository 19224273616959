export default [
  {
    path: "/mobile/bid",
    meta: {
      title: "认领往来单位",
      name: "mobileBid",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Supplier/Bid.vue"),
  },
  //   {
  //     path: "/mobile/login",
  //     meta: {
  //       title: "登录",
  //       name: "mobileLogin",
  //     },
  //     component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Common/MobileLogin.vue"),
  //   },
  {
    path: "/mobile/register",
    meta: {
      title: "登录/注册",
      name: "mobileRegister",
      noNeedAuthorize: true,
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Auth/Mobile/index.vue"),
  },
  {
    path: "/mobile/init",
    meta: {
      title: "注册",
      name: "mobileInit",
      noNeedAuthorize: true,
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Auth/Mobile/init.vue"),
  },
  {
    path: "/mobile/quote/info/bid",
    meta: {
      title: "报价",
      name: "mobileQuoteInfoBid",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Quote/MateQuoteBid/Mobile.vue"),
  },
];
