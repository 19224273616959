import Vue from "vue";
import router from "@/router";
import abpUtil from "@/lib/abp/abpUtil";
class ShowError {
  constructor(error) {
    this.error = error;
    if (error.response && !error.response.data) {
      this.handleDefault();
    } else if (!error.response) {
      // 应该不会出现response没有的情况，这个axios返回的数据
      this.showError(error.message ? { message: error.message, details: "" } : this.defaultError);
    } else {
      this.handleResponseReject(error.response);
    }
  }
  basePath = "/";
  defaultError = {
    message: "温馨提示",
    details: "请求服务器发生错误，未登录、注册或缺少权限都可能导致，请重试或检查并联系管理员！",
  };

  defaultError401 = {
    message: "您的身份验证未通过!",
    details: "需登录用户才能执行此操作，请检查是否登录或注册模来模往！",
  };

  defaultError403 = {
    message: "您的权限验证未通过!",
    details: "您暂无执行此操作的权限，请联系管理员添加权限。",
  };

  defaultError404 = {
    message: "404-找不到资源!",
    details: "在服务器上找不到请求的资源。",
  };

  handleDefault() {
    switch (this.error.response.status) {
      case 401:
        this.handleUnAuthorizedRequest(this.showError(this.defaultError401), this.basePath);
        break;
      case 403:
        this.showError(this.defaultError403);
        break;
      case 404:
        this.showError(this.defaultError404);
        break;
      default:
        this.showError(this.defaultError);
        break;
    }
  }
  handleResponseReject(response) {
    let { data, status } = response;
    let p = this.showError(data.error); // || this.defaultError

    // 未登录状态报401需要跳转
    if (status === 401) {
      // this.handleUnAuthorizedRequest(p, data.targetUrl || this.basePath)
      p.then(() => {
        abpUtil.goAuthRedirect();
      });
    }
  }
  showError(errObj) {
    let page = router.currentRoute.meta ? router.currentRoute.meta.title || "" : "";
    page = page ? `【${page}】` : "";
    const url = "<br>" + this.error.config.url;

    const title = errObj && errObj.message ? errObj.message : this.defaultError.message;

    // const defaultError = errObj && errObj.message ? `请尝试刷新${page}或与管理员联系！` : this.defaultError.details;
    const defaultError = `请求服务器发生错误，未登录、注册或缺少权限都可能导致，请尝试刷新${page}或与管理员联系！${url}`;

    // 老的错误code会返回0，这时候，只有message，details是空的；
    // 后来改成code默认丢出10000，默认message为温馨提示，details为丢出信息，message也可由后台丢出。

    const content = errObj && errObj.details ? errObj.details : errObj.code === 0 ? defaultError : "";

    return Vue.prototype.$alert(content, title, {
      dangerouslyUseHTMLString: true,
      type: "warning",
    });
    // return Vue.prototype.$notify({
    //   type: "warning",
    //   title: title,
    //   message: content,
    //   dangerouslyUseHTMLString: true,
    // });
  }

  handleTargetUrl(targetUrl) {
    location.href = targetUrl || abp.appPath;
  }
  // 进行页面跳转
  handleUnAuthorizedRequest(messagePromise, targetUrl = this.basePath) {
    if (messagePromise instanceof Promise) {
      messagePromise.then(() => this.handleTargetUrl(targetUrl));
    } else {
      this.handleTargetUrl(targetUrl);
    }
  }
}
export default ShowError;
