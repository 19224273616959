import Vue from "vue";
import Router from "vue-router";
import beforeEachHooks from "./beforeEachHooks";

import routes from "./commonRoutes";

Vue.use(Router);
const routerInstance = new Router({
  mode: "history",

  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: routes,
});

Object.values(beforeEachHooks).forEach((hook) => {
  routerInstance.beforeEach(hook);
  // beforeEach返回一个用来移除此hook的函数
});

export default routerInstance;
