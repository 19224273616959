<!--  -->
<template>
  <div class="app-nav">
    <!-- <div class="d-flex jc-center">
      <avatar-menu>
        <v-list-item link>
          <v-list-item-title>设置</v-list-item-title>
        </v-list-item>
      </avatar-menu>
    </div>

    <v-divider class="mx-3 mt-4"></v-divider> -->

    <!-- <router-link v-for="nav in navList.filter((o) => o.show)" :key="nav.text" :to="nav.to">
      <div class="nav-item-wrap" :class="[isActive(nav) && 'active-nav-item']">
        <v-icon :color="isActive(nav) ? 'primary' : null" class="nav-icon">{{ nav.icon }}</v-icon>

        <p class="nav-item-title">{{ nav.title }}</p>
      </div>
    </router-link> -->
    <el-menu :default-openeds="openeds" router class="el-menu-vertical-demo" style="width: 150px; overflow: hidden">
      <el-menu-item index="/Home">
        <i class="el-icon-document"></i>
        <span class="topic" slot="title">首页</span>
      </el-menu-item>
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span class="topic">询价中心</span>
        </template>
        <el-menu-item index="/quote/add">快速询价</el-menu-item>
        <el-menu-item index="/quote/list">询价列表</el-menu-item>
        <el-menu-item index="/quote/list2?isdraft=true">草稿箱</el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span class="topic">报价中心</span>
        </template>
        <el-menu-item index="/quote/mate?type=0">匹配的询价</el-menu-item>
        <el-menu-item index="/quote/mate?type=1">已报价</el-menu-item>
        <!-- <el-menu-item index="/quote/mate?type=2">已收藏</el-menu-item> -->
        <!-- <el-menu-item index="/quote/mate?type=3">已完成</el-menu-item> -->
        <el-menu-item index="/quote/mate?type=4">邀请我报价</el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <template  slot="title">
          <i class="el-icon-location"></i>
          <span class="topic">订单中心</span>
        </template>
        <el-menu-item index="/quote/orderList">我发出的订单</el-menu-item>
        <el-menu-item index="/quote/mate/orderList">我收到的订单</el-menu-item>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span class="topic">账户</span>
        </template>
        <el-menu-item index="/Setting/Supplier">供应商</el-menu-item>
        <el-menu-item index="/Setting/Company/Info">企业资料</el-menu-item>
        <el-menu-item index="/Setting/Company/Account">账户管理</el-menu-item>
        <el-menu-item index="/Setting/Company/Agreement">保密协议</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "AppNavList",
  props: {
    name: String,
    path: String,
  },
  data() {
    return {
      openeds: ["1", "2"],
      navList: abp.$power.isFactory
        ? [
            { icon: "mdi-home", title: "首页", to: "/Home", name: "Home", show: true },
            // { icon: "iconfont icon-chengben", title: "询价", to: "/Quote", name: "Quote", show: true },
            // { icon: "iconfont icon-icon-kaidan", title: "开单", to: "/JGKD", name: "JGKD", show: true },
            // { icon: "iconfont icon-renwu", title: "任务", to: "/ProcessTask", name: "ProcessTask", show: true },
            // {
            //   icon: "iconfont icon-yiban-",
            //   title: "财务",
            //   to: "/Finance",
            //   name: "Finance",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-caigou_feiyongshenqing",
            //   title: "核销",
            //   to: "/Finance/Cancellation",
            //   name: "FinanceCancellation",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-zongzhangjiqita",
            //   title: "成本",
            //   to: "/Finance/SummaryReport",
            //   name: "FinanceSummaryReport",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-jiaohusheji",
            //   title: "资源",
            //   to: "/Resource",
            //   name: "Resource",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-shejiguanli",
            //   title: "标准",
            //   to: "/Standard",
            //   name: "Standard",
            //   show: true,
            // },
            // { icon: "iconfont icon-setting", title: "设置", to: "/Setting/BasicSetting", name: "Setting", show: true },
            { icon: "iconfont icon-setting", title: "资料", to: "/Setting/Company", show: true },
            // { icon: "mdi-cog-outline", title: "设置", to: "/Setting/Info", name: "Setting", show: true },
          ]
        : [
            { icon: "mdi-home", title: "首页", to: "/Home", show: true },
            // {
            //   icon: "iconfont icon-chengben",
            //   title: "报价",
            //   to: "/Processor/ProcessQuote",
            //   name: "ProcessorProcessQuote",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-icon-kaidan",
            //   title: "订单",
            //   to: "/Processor/ProcessTask",
            //   name: "ProcessorProcessTask",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-renwu",
            //   title: "生产",
            //   to: "/Processor/ProcessTaskReport",
            //   name: "ProcessorProcessTaskReport",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-yiban-",
            //   title: "财务",
            //   to: "/Finance",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-caigou_feiyongshenqing",
            //   title: "结账",
            //   name: "ProcessorUnitFeeList",
            //   to: "/Processor/Finance/UnitFeeList",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-zongzhangjiqita",
            //   title: "费用",
            //   name: "ProcessorProcessQuote",
            //   to: "/",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-jiaohusheji",
            //   title: "资源",
            //   name: "Resource",
            //   to: "/Resource",
            //   show: true,
            // },
            // {
            //   icon: "iconfont icon-shejiguanli",
            //   title: "标准",
            //   name: "Standard",
            //   to: "/Standard",
            //   show: true,
            // },
            // { icon: "iconfont icon-setting", title: "设置", to: "/Setting/BasicSetting", show: true },
            { icon: "iconfont icon-setting", title: "资料", to: "/Setting/Company", show: true },
            // { icon: "mdi-cog-outline", title: "设置", to: "/Setting/Info", name: "Setting", show: true },
          ],
    };
  },
  watch: {
    path(val) {
      let arr = this.navList;
      for (var i = 0; i < arr.length; i++) {
        let nav = arr[i];
        if (this.isActive(nav)) {
          nav.to = val;
        }
      }
    },
  },
  methods: {
    isActive(nav) {
      return this.name === nav.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-nav {
  background: #fff;
  padding-top: 14px;
  height: calc(100vh - 55px);
  overflow-y: scroll;
  width: 160px;
}
.nav-item-wrap {
  padding: 6px;
  font-size: 14px;
  color: rgb(119, 124, 136);
  text-align: center;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  .nav-item-title {
    font-size: 14px;
  }
  &.active-nav-item {
    color: #409eff;
    background: #f6f7f9;
    i.primary--text {
      color: #409eff !important;
      caret-color: #409eff !important;
    }
  }
  &:hover {
    background: #f6f7f9;
  }
  .nav-icon {
    font-size: 20px;
    margin-right: 8px;
  }
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: fade(rosybrown, 60%);
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: fade(rosybrown, 30%);
}
.topic{
  font-weight: 800;
}
</style>
