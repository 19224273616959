import Vue from "vue";
import dayjs from "dayjs";
import { toFixed } from "../core/utils";
// function isDate(t) {
//   return t instanceof Date ? t : new Date(t);
// }
const filters = {
  dateTimeConvert(time) {
    return time ? dayjs(time).format("YYYY-MM-DD HH:mm:ss") : "";
  },
  withNoSecendsConvert(time) {
    return time ? dayjs(time).format("YYYY-MM-DD HH:mm") : "";
  },
  dateConvert(time) {
    return time ? dayjs(time).format("YYYY-MM-DD") : "";
  },
  simpleTimeConvert(time) {
    return time ? dayjs(time).format("M-D H:m") : "";
  },
  simpleYearConvert(time) {
    return time ? dayjs(time).format("YY/MM/DD H:m") : "";
  },
  dateCompareFilter(time) {
    return time ? dayjs(time).fromNow() : "";
  },
  // 如果已经包含地址直接返回，未包含则加上域名
  urlIncludeHttp(url) {
    if (!url) return "";
    // return url.indexOf('http') === 0 ? url : Vue.prototype.$rootUrl + url;
    return url.indexOf("http") === 0
      ? url
      : Vue.prototype.$$.isProduction
      ? location.origin
      : Vue.prototype.$rootUrl + url;
  },
  toFixed: toFixed,
  // //(要处理的值，保留几位，默认值)
  // toFixed(number, index, defaultValue) {
  //   defaultValue = typeof defaultValue !== "undefined" ? defaultValue : 0;
  //   number = Number(number);
  //   return number && !isNaN(number) ? number.toFixed(index || 0) : defaultValue;
  // },
  percent(number, index) {
    if (isNaN(Number(number))) {
      return "数据格式出错";
    }
    return ((number || 0) * 100).toFixed(index || 0) + "%";
  },
  shortHandDate(time) {
    if (!time) return "";
    time = dayjs(time);
    let nowYear = new Date().getFullYear();
    if (nowYear === time.year()) return time.format("MM-DD");
    return time.format("YYYY-MM-DD");
  },
  shortHandTime(time) {
    if (!time) return "";
    time = dayjs(time);
    let nowYear = new Date().getFullYear();
    if (nowYear === time.year()) return time.format("MM-DD HH:mm");
    // (time.format("HH") > 12 ? "上午" : "下午")
    return time.format("YYYY-MM-DD HH:mm");
  },
  shortHandTimeDetailed(time) {
    if (!time) return "";
    time = dayjs(time);
    let nowYear = new Date().getFullYear();
    if (nowYear === time.year()) return time.format("MM-DD HH:mm");
    return time.format("YYYY-MM-DD HH:mm");
  },
  fromNow(time) {
    if (!time) return "";
    time = dayjs(time);
    let now = dayjs();
    if (time.year() === now.year() && time.month() === now.month()) {
      if (time.date() === now.date()) {
        return time.fromNow();
      } else if (time.date() === now.date() - 1) {
        return time.format("昨天 HH:mm");
      } else if (time.week() === now.week()) {
        return time.format("本周dd HH:mm");
      } else if (time.week() === now.week() - 1) {
        return time.format("上周dd HH:mm");
      }
    }
    return filters.shortHandTime(time);
  },

  hour2Time(totalHour) {
    let hour = Math.floor(totalHour);
    let minute = Math.floor((totalHour % 1) * 60);
    let second = Math.round((totalHour - hour - minute / 60) * 3600);

    return hour + ":" + minute + ":" + second;
  },
  time2Hour(timeStr) {
    let hourArr = [1, 1 / 60, 1 / 3600];
    let hour = 0;
    if (timeStr) {
      timeStr.split(":").forEach((el, i) => {
        let hourTemp = el * hourArr[i];
        hour += isNaN(hourTemp) ? 0 : hourTemp;
      });
    }
    return isNaN(hour) ? 0 : hour;
  },
};
export default filters;
