import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import "./lib/abp/index.js";
// import "./lib/abp/coverAbp"
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/axios/index.js";
import "./global.js";
import abpUtil from "./lib/abp/abpUtil";
import "./plugins/element-ui";
import "./assets/scss/style.scss";
import "./assets/iconfont/iconfont.css";

// ------vform------
import "babel-polyfill";
// import "./utils/debug-console"

import "@/styles/index.scss";

import VueAMap from "vue-amap";
Vue.use(VueAMap);
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: "c269c3fb0b3aac820ce38429936647b4",
  // 插件集合 （插件按需引入）
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.Circle",
    "AMap.CircleEditor",
    "AMap.DistrictSearch",
    "AMap.CircleMarker",
    "AMap.Polyline",
    "AMap.RangingTool",
    "AMap.CitySearch",
    "AMap.MoveAnimation",
    "AMap.RectangleEditor",
    "AMap.Rectangle",
    "AMap.LngLat",
    "AMap.Bounds",
    "AMap.Map",
    "AMap.Polygon",
    "AMap.Geocoder",
    // 'AMap.Object3DLayer', 'AMap.Object3D'
  ],
  viewMode: "3D", // 开启3D视图,默认为关闭
});
// 高德的安全密钥
window._AMapSecurityConfig = {
  securityJsCode: "49a7204a1ad87c96200b393066cb152a",
};

import Clipboard from "v-clipboard";

Vue.use(Clipboard);

import VueCropper from "vue-cropper";
Vue.use(VueCropper);

if (process.env.NODE_ENV === "development") {
  Vue.config.devtools = true;
  Vue.config.productionTip = true;
  Vue.mixin({
    methods: {
      logArgs(...args) {
        console.log("logArgs:", args);
      },
    },
  });
}

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

abpUtil.getAbpConfig().then(() => {
  window.app = new Vue({
    store,
    router,
    vuetify,
    pinia,
    render: (h) => h(App),
  }).$mount("#app");
});
