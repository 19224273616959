<script>
import UploadFilesLgInner from "../Upload/UploadFilesLgInner";
export default {
  data() {
    return {};
  },
  render() {
    const data = {
      props: {
        ...this.$attrs,
      },
      on: {
        ...this.$listeners,
        click() {},
      },
    };
    return <UploadFilesLgInner {...data}>{this.$slots.default}</UploadFilesLgInner>;
  },
};
</script>

<style lang="scss" scoped></style>
