import OSS from "ali-oss";

export const random_string = (len) => {
  len = len || 32;
  const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const maxPos = chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};

export const uploadFileAntd = (options, name) => {
  const { file, onProgress, onSuccess, onError } = options;
  console.log(file);

  const ossClient1 = new OSS({
    // region: 'oss-cn-shangha',
    accessKeyId: "LTAI4FoFeE9LDrKiSAks92GF",
    accessKeySecret: "SOMCPAvQdPZhbGruRmtjD8Glioh0Ah",
    bucket: "itianwangjmould",
    endpoint: "oss-cn-shanghai.aliyuncs.com",
    secure: true,
  });

  return new Promise(async (resolve, reject) => {
    const filePath = `/file/${new Date().getFullYear()}/${
      new Date().getMonth() + 1
    }/${new Date().getDate()}/${random_string(6)}_${new Date().getTime()}/${name}`;
    let result = null;
    if (file.size / 1024 / 1024 > 20) {
      result = await ossClient1
        .multipartUpload(filePath, file, {
          progress: async (percent) => {
            console.log(percent);
            onProgress({ percent: Math.floor(percent * 10000) / 100 });
          },
        })
        .catch((error) => {
          onError(error);
          reject(error);
        });
    } else {
      result = await ossClient1
        .multipartUpload(filePath, file, {
          parallel: 1,
          partSize: 1024 * 1024 * 100,
          progress: async (percent) => {
            onProgress({ percent: Math.floor(percent * 10000) / 100 });
          },
        })
        .catch((error) => {
          onError(error);
          reject(error);
        });
    }

    const successRes = {
      url: result.res.requestUrls[0].split("?")[0],
      name: result.name,
      status: result.res.status == 200 ? "done" : "error",
    };
    onProgress({ percent: 100 });
    onSuccess(successRes, result.url);
    resolve(successRes);
  });
};
