export default [
  {
    path: "Setting",
    meta: {
      title: "平台管理",
      name: "Setting",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting"),
    children: [
      {
        path: "BasicSetting",
        meta: {
          title: "功能设置",
          name: "BasicSetting",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/BasicSetting"),
      },
      {
        path: "ModuleInfo",
        meta: {
          title: "模块设置",
          name: "ModuleInfo",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/ModuleInfo"),
      },
      // {
      //   path: "Info",
      //   meta: {
      //     title: "基本信息",
      //     name: "Setting",
      //   },
      //   component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Info"),
      // },
      // {
      //   path: "Permissions",
      //   meta: {
      //     title: "权限管理",
      //     name: "Setting",
      //   },
      //   component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Permissions"),
      // },
      {
        path: "Organization",
        meta: {
          title: "组织架构",
          name: "Organization",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Organization"),
      },
      {
        path: "Role",
        meta: {
          title: "角色管理",
          name: "Role",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Role"),
      },
      {
        path: "User",
        meta: {
          title: "员工信息",
          name: "User",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/User/index.vue"),
      },
    ],
  },
  {
    path: "/Setting/Company",
    meta: {
      title: "企业信息",
      name: "Company",
    },
    redirect: "/Setting/Company/Info",
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Company/index.vue"),
    children: [
      {
        path: "Info",
        meta: {
          title: "供应商",
          name: "Info",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Company/part/Info/index.vue"),
      },
      {
        path: "Info",
        meta: {
          title: "企业信息",
          name: "Info",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Company/part/Info/index.vue"),
      },
      {
        path: "Account",
        meta: {
          title: "账户管理",
          name: "Account",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Company/part/Account/index.vue"),
      },
      {
        path: "Agreement",
        meta: {
          title: "保密协议",
          name: "Agreement",
        },
        component: () =>
          import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Company/part/Agreement/index.vue"),
      },
    ],
  },
  {
    path: "Setting/Supplier",
    meta: {
      title: "我的供应商",
      name: "SettingSupplier",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Supplier/index.vue"),
  },
  // {
  //   path: "/Setting/Demo",
  //   meta: {
  //     title: "Demo",
  //     name: "Demo",
  //   },
  //   component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Demo/table"),
  // },
];
export const name = "设置";
