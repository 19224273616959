import Vue from "vue";
// import { extend } from "lodash-es"
import { extend } from "@/core/utils/index";
import $$ from "@/core/utils/utilClass";

class Util {
  authorization = {
    encrptedAuthTokenName: "token",
    wxSessionId: "wx_sessionId",
    // 'enc_auth_token'
  };

  deleteCookie() {
    abp.utils.deleteCookie(this.authorization.wx_sessionId, abp.appPath);
    abp.utils.deleteCookie(this.authorization.encrptedAuthTokenName, abp.appPath);
    abp.auth.clearToken();
  }
  async weixinSPAOauth() {
    this.deleteCookie();
    location.replace(`https://www.imouldyun.com/weixin/spaoauth?returnurl=${encodeURIComponent(location.href)}`);
  }
  async goAuthRedirect() {
    const { isWX, isPC } = $$;
    // if (process.env.NODE_ENV === "production") {
    if (true) {
      if (isWX) {
        // location.href = `https://auth.imouldyun.com/weixin/oauth?returnUrl=${location.href}&referer=jmouldyun`;
        // this.weixinSPAOauth();
        location.href = `/login?returnUrl=${encodeURIComponent(location.pathname +location.search)}&referer=jmouldyun`;
      } else {
        if (isPC) {
          location.href = `/login?returnUrl=${encodeURIComponent(location.pathname +location.search)}&referer=jmouldyun`;
        } else {
          location.href = `/login?returnUrl=${encodeURIComponent(location.pathname +location.search)}&referer=jmouldyun`;
        }
      }
      // location.reload();
    } else {
      const data = {
        returnurl: "/",
        tenancyName: process.env.VUE_APP_TENANCYNAME || "default",
        userName: process.env.VUE_APP_USERNAME || "admin",
        password: process.env.VUE_APP_PASSWORD || "333666999",
        vercode: "",
      };
      const rep = await Vue.prototype.$axios.post("/api/auth/account/authenticate", data);
      const tokenExpireDate = new Date(new Date().getTime() + 1000 * rep.expireInSeconds);
      abp.auth.setToken(rep.accessToken, rep.refreshToken, tokenExpireDate);
      abp.utils.setCookieValue(
        this.authorization.encrptedAuthTokenName,
        rep.encryptedAccessToken,
        tokenExpireDate,
        abp.appPath
      );
      location.reload();
    }
  }
  setToken(rep) {
    const tokenExpireDate = new Date(new Date().getTime() + 1000 * rep.expireInSeconds || 1);
    abp.auth.setToken(rep.accessToken, rep.refreshToken, tokenExpireDate);
    abp.utils.setCookieValue(
      this.authorization.encrptedAuthTokenName,
      rep.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath
    );
  }

  getAbpConfig() {
    return Vue.prototype
      .$ajax("/UserConfiguration/GetAll")
      .catch(({ err, hide }) => {
        // this.deleteCookie();
        // console.log(document.cookie)
        if (err.response.status === 302) {
          this.authenticate();
          hide();
        } else {
          document.body.innerHTML = '<h1 style="text-align:center;margin:40px 0;">404,服务器正在更新请稍后</h1>';
        }
        throw err;
        // document.body.innerHTML=JSON.stringify(err);
      })
      .then((res) => {
        function toLowerCaseKeys(obj) {
          Object.keys(obj).forEach((el) => {
            obj[el.toLowerCase()] = obj[el];
            delete obj[el];
          });
        }
        toLowerCaseKeys(res.auth.allPermissions || {}); //全部权限
        toLowerCaseKeys(res.auth.grantedPermissions || {}); //已有权限
        toLowerCaseKeys(res.features.allFeatures || {});
        toLowerCaseKeys(res.setting.values || {});
        //塞入abp.session等
        window.abp = extend(true, window.abp, res);
        Object.assign(abp.session, res.loginInformation);
        this.initTenantPower();
        return abp;
      });
  }
  initTenantPower() {
    if (!abp.$power) {
      let power = this.getPower();
      power.isFactory = abp.features.isEnabled("MESManufacture");
      power.isProcessor = abp.features.isEnabled("MESSupplier");
      power.isCustomer = abp.features.isEnabled("MESCustomer");
      abp.$power = {};
      Object.keys(power).forEach((key) => {
        Object.defineProperty(abp.$power, key, {
          writable: false,
          value: power[key],
        });
      });
      Object.defineProperty(abp.$power, "isTenant", {
        writable: false,
        value: function (tenantName) {
          const tenantMap = {
            大盛: 406,
            美多: 233,
            正立: 28,
            辉达: 214,
            力合: 1554,
            宁光: 490,
            星泰: 1121,
            诚慧: 1763,
            万豪: 185,
            跃飞: 1779,
          };
          return abp.session.tenantId === tenantMap[tenantName];
        },
      });
    }
  }

  getPower() {
    const isGranted = abp.auth.isGranted;
    const getBoolean = abp.setting.getBoolean;
    // 回单审核权限
    const TaskVerifyPower = isGranted("Module.ProcessTask.Button.Verify");
    const TaskDeductPower = !!abp.auth.grantedPermissions["Module.ProcessTask.Button.Deduct"];
    // 有回单审核或者回单查看权限
    const TaskVerifyP = TaskVerifyPower || TaskDeductPower || isGranted("Module.ProcessTask.Button.VerifyRead");

    // 开单审核
    const JGKDConfirmP =
      (isGranted("Menu.MES.Tenancy.JGKD") && !getBoolean("MES.MustConfirmProcess")) ||
      isGranted("Module.JGKD.Button.ConfirmProcess");

    // 开单页面
    const JGKDOpenP = isGranted("Menu.MES.Tenancy.JGKD");
    // 报工记录
    const ProcessTaskReport = isGranted("Menu.MES.Tenancy.ProcessTaskReport");
    // 直接报工
    const DocumentaryReportP = isGranted("Module.ProcessTask.Button.DocumentaryReport");
    // 回填金额查看
    const TaskViewP =
      isGranted("Module.ProcessTaskProcessor.Button.Verify") ||
      isGranted("Module.ProcessTaskProcessor.Button.VerifyRead");

    const JGKDEditP = isGranted("Module.JGKD.Button.Edit");

    return {
      TaskVerifyP,
      JGKDConfirmP,
      JGKDOpenP,
      TaskViewP,
      ProcessTaskReport,
      DocumentaryReportP,
      // isMeiDuo: abp.session.tenantId === 233,
      JGKDEditP,
    };
  }
  // 将url中某个字符去掉
  funcUrlDel(name) {
    var loca = window.location;
    var baseUrl = loca.origin + loca.pathname + "?";
    var query = loca.search.substr(1);
    if (query.indexOf(name) > -1) {
      var obj = {};
      var arr = query.split("&");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].split("=");
        obj[arr[i][0]] = arr[i][1];
      }
      delete obj[name];
      var targetUrl =
        baseUrl + JSON.stringify(obj).replace(/["{}]/g, "").replace(/:/g, "=").replace(/,/g, "&") + loca.hash;
      return targetUrl;
    } else {
      return loca.href;
    }
  }

  // getAbpConfig() {
  //   return Vue.prototype
  //     .$ajax("/UserConfiguration/GetAll")
  //     .catch(({ err, hide }) => {
  //       if (err.response.status === 302) {
  //         this.goAuthRedirect()
  //         hide()
  //       } else {
  //         document.body.innerHTML = '<h1 style="text-align:center;margin:40px 0;">404,服务器正在更新请稍后</h1>'
  //       }
  //       throw err
  //     })
  //     .then((res) => {
  //       function toLowerCaseKeys(obj) {
  //         Object.keys(obj).forEach((el) => {
  //           obj[el.toLowerCase()] = obj[el]
  //           delete obj[el]
  //         })
  //       }
  //       toLowerCaseKeys(res.auth.allPermissions || {}) // 全部权限
  //       toLowerCaseKeys(res.auth.grantedPermissions || {}) // 已有权限
  //       toLowerCaseKeys(res.features.allFeatures || {})
  //       toLowerCaseKeys(res.setting.values || {})
  //       // 塞入abp.session等
  //       window.abp = extend(true, abp, res)
  //       Object.assign(abp.session, res.loginInformation)
  //     })
  //     .catch((e) => {
  //       console.log(e)
  //       document.body.innerHTML = '<h1 style="text-align:center;margin:40px 0;">404,服务启动失败请稍后</h1>'
  //     })
  // }
}
export default new Util();
