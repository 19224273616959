import Vue from "vue";
import { runAsync } from "./core/utils";
import $$ from "./core/utils/utilClass";
import "./lib/abp/coverAbp";

// import "@linusborg/vue-simple-portal"
/* ------------------------杂七杂八的功能------------------------------ */
var EventBus = new Vue();

Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus;
    },
  },
});
/* ------------------------Abp Global ------------------------------ */

/* ------------------------API------------------------------ */
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn"); // 全局使用简体中文
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);
import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);
/* ------------------------Vue Global Variable------------------------------ */
Object.defineProperties(Vue.prototype, {
  $$: {
    get() {
      return $$;
    },
  },
  $rootUrl: {
    get() {
      return $$.url;
    },
  },
  $dayjs: {
    get() {
      return dayjs;
    },
  },
  $runAsync: {
    get() {
      return runAsync;
    },
  },
});

/* ------------------------公共filter ------------------------------ */
import Filters from "./filters/index.js";
for (let key in Filters) {
  Vue.filter(key, Filters[key]);
}

/* ------------------------公共directive ------------------------------ */
import Directives from "./directives/index.js";
Vue.use(Directives); // 全局使用directive文件;

/* ------------------------Vue Global Components---全局组件------------------------------ */
const files = require.context("./components/Base", true, /\.vue$/);
files.keys().forEach((key) => {
  // let componentName = key.replace(/^\.\//, "").replace(/\.\w+$/, "");
  let componentName = key.match(/^.*\/(.*)\.vue/)[1];
  let componentConfig = files(key);
  Vue.component(componentName, componentConfig.default || componentConfig);
});

/* ------------------------Vue Global Components------------------------------ */

// import VXETable from "vxe-table";
// import "vxe-table/lib/style.css";

// Vue.use(VXETable);

import "./plugins/vxe/table";
