export default [
  {
    path: "/quote/add",
    name: "quoteAdd",
    meta: {
      title: "快速询价",
      name: "quote",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Quote/QuoteAdd"),
  },
  {
    path: "/quote/list",
    name: "quoteList",
    meta: {
      title: "询价列表",
      name: "quoteList",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Quote/QuoteList"),
  },
  {
    path: "/quote/orderList",
    name: "quoteOrderList",
    meta: {
      title: "我发出的订单",
      name: "quoteOrderList",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Order/OrdersIssued"),
  },
  {
    path: "/quote/orderDetail",
    name: "quoteOrderDetail",
    meta: {
      title: "订单详情",
      name: "quoteOrderDetail",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Order/OrdersIssued/Detail"),
    props: (route) => ({ orderId: route.query.orderId }),
  },
  {
    path: "/quote/mate/orderList",
    name: "matequoteOrderList",
    meta: {
      title: "我收到的订单",
      name: "matequoteOrderList",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Order/ReceivedOrders"),
  },
  {
    path: "/quote/mate/orderDetail",
    name: "matequoteOrderDetail",
    meta: {
      title: "订单详情",
      name: "matequoteOrderDetail",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Order/ReceivedOrders/Detail"),
    props: (route) => ({ orderId: route.query.orderId }),
  },
  {
    path: "/quote/info",
    name: "quoteInfo",
    meta: {
      title: "询价信息",
      name: "quoteInfo",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Quote/QuoteInfo"),
  },
  {
    path: "/quote/list2",
    name: "quoteList2",
    meta: {
      title: "草稿箱",
      name: "quotelist2",
    },
    component: () => import("@/views/Quote/QuoteList"),
  },
  {
    path: "/quote/mate",
    name: "quoteMate",
    meta: {
      title: "报价中心",
      name: "quoteMate",
    },
    component: () => import("@/views/Quote/MateQuoteList"),
  },
  // {
  //   path: "/quote/info/mate",
  //   name: "quoteInfoMate",
  //   meta: {
  //     title: "报价信息",
  //     name: "quoteInfoMate",
  //   },
  //   component: () => import("@/views/Quote/MateQuoteInfo"),
  // },
  {
    path: "/quote/info/bid",
    name: "quoteInfoBid",
    meta: {
      title: "报价",
      name: "quoteInfoBid",
    },
    component: () => import("@/views/Quote/MateQuoteBid"),
  },
];
export const name = "询价";
