<!--  -->
<template>
  <div class="logo-warp">
    <el-image v-if="src" class="logo-img" :src="url" fit="cover" :style="comStyle"></el-image>
    <div v-else class="logo-name" :style="comStyle">
      {{ name[0] }}
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoAvatar",
  props: {
    src: String,
    name: String,
    size: [String, Number],
    radius: [String, Number],
  },
  data() {
    return {};
  },
  computed: {
    comStyle() {
      let size = this.size;
      let radius = this.radius;
      let obj = {};
      radius && (obj.borderRadius = radius);
      if (size) {
        obj.width = size + "px";
        obj.height = size + "px";
        obj.lineHeight = size + "px";
        obj.fontSize = size / 2.1 + "px";
      }
      return obj;
    },
    url() {
      if (this.src.indexOf("http") > -1) {
        return this.src;
      } else {
        return "https://www.imouldyun.com" + this.src;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
.logo-name {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: #5e94ff;
  color: #fff;
  font-size: 26px;
  text-align: center;
  line-height: 48px;
}
</style>
