import Vue from "vue";
import Vuex from "vuex";
// 新加
import tagsView from "./tags-view";
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    tagsView,
  },
});
export default store;
