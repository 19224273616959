<template>
  <div class="el-upload el-upload--text">
    <v-overlay :value="percentMsg">
      <v-progress-circular
        v-if="percentMsg"
        :indeterminate="false"
        :rotate="0"
        :size="200"
        :value="percentMsg.percent"
        :width="6"
        color="white"
      >
        <div v-html="percentMsg.msg"></div>
      </v-progress-circular>
    </v-overlay>

    <el-upload
      ref="upload"
      :data="uploadData"
      action="https://www.imouldyun.com/api/cloudstorage/cloudfile/uploadfiles"
      :headers="headers"
      :accept="isImage ? 'image/*' : ''"
      drag
      :show-file-list="showFileList"
      :limit="tLimit"
      :multiple="multiple"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :before-upload="beforeAvatarUpload"
      :on-progress="handleProgress"
      :auto-upload="autoUpload"
      class="common-upload-file"
      v-on="$listeners"
    >
      <slot>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
      </slot>
      <slot name="tip">
        <div v-if="showTip" slot="tip" class="el-upload__tip" style="line-height: normal">
          文件上传不可超过{{ size }}MB{{ limitTip }}
        </div>
      </slot>
    </el-upload>
    <div v-if="!showFileList && !$slots.list" class="text-left">
      <FileListShow :file-list="value" @remove="handleRemove({ url: $event.filePath })"></FileListShow>
      <!-- <div class="d-flex">
        <p class="mr-2">同步文件到云端</p>
        <el-switch v-if="isLocalSystem" v-model="isSyncCloud"></el-switch>
      </div> -->
    </div>
    <slot v-else name="list"></slot>
  </div>
</template>
<script>
import { toFixed } from "@/core/utils";
export default {
  name: "UploadFilesLgInner",
  props: {
    value: Array,
    size: {
      default: 600,
      type: Number,
    },
    showFileList: {
      type: Boolean,
      default: false,
    },
    limit: Number,
    multiple: {
      default: true,
      type: Boolean,
    },
    isImage: Boolean,
    usePath: {
      default: false,
      type: Boolean,
    },
    basePath: String,
    autoUpload: {
      default: true,
      type: Boolean,
    },
    showTip: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      headers: { "X-XSRF-TOKEN": abp.security.antiForgery.getToken(), Authorization: "Bearer " + abp.auth.getToken() },
      fileList: [],
      fileCache: [],
      // percent: null,
      startTime: null,
      activeLen: null,
      progressPercent: {},
      fileName: "",
      isLocalSystem: false,
      // isSyncCloud: true,
    };
  },
  computed: {
    uploadData() {
      // var obj = { isSyncCloud: true }
      var obj = {};
      if (this.usePath) {
        Object.assign(obj, { usePath: this.usePath, filePath: this.basePath + this.fileName });
      }
      return obj;
    },
    percentMsg() {
      let ins = this.progressPercent;
      if (Object.keys(ins).length === 0) return null;
      let len = this.activeLen;
      let data = Object.keys(ins)
        .map((key) => ins[key])
        .reduce((a, b) => {
          return {
            loaded: a.loaded + b.loaded,
            total: a.total + b.total,
            percent: a.percent + b.percent,
          };
        });
      let percent = data.loaded / data.total;
      percent = Math.ceil(percent * 100);
      percent = percent > 99 ? 99 : percent;
      // console.log(len, percent, data.percent / len);

      let spead = toFixed(data.loaded / ((+new Date() - this.startTime) / 1000), 1);
      return {
        percent: percent,
        msg: [
          `共计${len}个正在上传`,
          `${toFixed(data.loaded, 1)} / ${toFixed(data.total, 1)} MB (${percent}%)`,
          ` 平均网速: ${spead}MB/s`,
        ].join("<br>"),
      };
    },
    limitTip() {
      return this.tLimit ? `，限制上传${this.tLimit}个文件` : "";
    },
    tLimit() {
      if (this.limit <= 0) return void 0;
      return this.limit ?? (this.multiple ? void 0 : 1);
    },
  },
  watch: {
    value: "setFileListByValue",
  },
  created() {
    this.$ajax("/File/GetRequestInfo").then((res) => {
      this.isLocalSystem = res.isLocalSystem;
    });
    this.setFileListByValue();
  },
  methods: {
    handleProgress(ev, file, fileList) {
      let loaded = ev.loaded / 1024 / 1024,
        total = ev.total / 1024 / 1024;
      let percent = Math.ceil(ev.percent);
      percent = percent > 99 ? 99 : percent;
      this.activeLen = fileList.filter((el) => el.status !== "success").length;
      this.$set(this.progressPercent, file.uid, {
        total,
        loaded,
        percent,
      });
    },
    beforeAvatarUpload(file) {
      this.fileName = file.name;
      this.uploadData.files = file;
      return new Promise((resolve, reject) => {
        this.startTime = +new Date();
        const isLt2M = file.size / 1024 / 1024 < this.size;
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过" + this.size + "MB!");
        }
        if (isLt2M) {
          resolve();
        } else {
          reject();
        }
      });
    },
    handleRemove(file) {
      var val = this.value.filter((el) => el.filePath !== file.url);
      this.$emit("input", val);
      this.$emit("change", val);
      let removeFile = this.value.filter((el) => el.filePath === file.url);
      if (!removeFile[0]) throw new Error("删除出现异常");
      this.$emit("remove", removeFile[0]);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(response, file, fileList) {
      response.result.creationTime = this.$dayjs().format("YYYY-MM-DD HH:mm:ss");
      response.result.creatorUserName = abp.session.user?.name;
      // 缓存起来只有当全部成功才向上抛出
      this.fileCache = this.fileCache.concat(response.result);
      if (fileList.every((el) => el.status === "success")) {
        // console.log(this.value);
        this.$message({ type: "success", message: "文件上传成功！" });
        this.$emit("input", this.value.concat(this.fileCache));
        this.$emit("change", this.value.concat(this.fileCache));
        this.$emit("add", this.fileCache);
        this.fileCache = [];
        this.afterUpload();
      }
      // error: null
      // result: {success: true, filePath: "https://itianwangmlmwtest.oss-cn-shanghai.aliyuncs…20/05/05/d78bc16f-28e4-4767-ac4d-86f95cf935bd.jpg", fileName: "006cqKYCly1g3mlvv6kr4j30j60j7ncm.jpg", fileSize: 74.3525390625, msg: null, …}
      // success: true
      // targetUrl: null
      // unAuthorizedRequest: false
    },
    handleError() {
      this.$message({ type: "error", message: "文件上传失败！" });
      this.afterUpload();
    },
    afterUpload() {
      this.progressPercent = {};
      this.startTime = null;
      this.activeLen = null;
      // this.fileName = "";
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.tLimit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    setFileListByValue() {
      if (!this.value) {
        this.$emit("input", []);
        return;
      }
      this.fileList = this.value
        ? this.value.map((file) => ({
            name: file.fileName || file.filePath,
            url: file.filePath,
            uid: file.fileId || file.filePath,
            creationTime: file.creationTime,
            creatorUserName: file.creatorUserName,
          }))
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.common-upload-file::v-deep {
  max-width: calc(100vw - 48px);
  > div,
  > div > .el-upload-dragger {
    max-width: 100% !important;
  }
}
</style>
