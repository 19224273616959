export default [
  {
    path: "Processor/ProcessQuote",
    meta: {
      title: "报价大厅",
      name: "ProcessorProcessQuote",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Processor/ProcessQuote"),
  },
  {
    path: "Processor/ProcessTask",
    meta: {
      title: "加工订单",
      name: "ProcessorProcessTask",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/IFrame/index"),
    props: {
      href: "https://www.imouldyun.com/Home/Show?name=../MES/ProcessTaskProcessor",
    },
  },
  {
    path: "Processor/ProcessTaskReport",
    meta: {
      title: "生产记录",
      name: "ProcessorProcessTaskReport",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/IFrame/index"),
    props: {
      href: "https://www.imouldyun.com/Home/Show?name=../Processor/ProcessTaskReport",
    },
  },
  {
    path: "Processor/Finance/UnitFeeList",
    meta: {
      title: "结账",
      name: "ProcessorUnitFeeList",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/IFrame/index"),
    props: {
      href: "https://www.imouldyun.com/Home/Show?name=../Processor/UnitFeeList",
    },
  },
];
