import HomeRoutes from "./routes/home";
import SettingRoutes from "./routes/setting";
import ProcessorRoutes from "./routes/processor";
import AuthRoutes from "./routes/auth";
import QuoteRoutes from "./routes/quote";
import MobileRoutes from "./routes/mobile";

let config = [
  {
    path: "/",
    meta: {
      title: "首页",
    },
    redirect: "/Home",
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/index"),
    props: (route) => {
      console.log("/");
      return { path: route.path, name: route.meta.name };
    },
    children: HomeRoutes.concat(...[ProcessorRoutes, SettingRoutes, QuoteRoutes]),
  },
  {
    meta: {
      title: "404",
    },
    path: "*",
    component: () => import(/* webpackChunkName: "group-other" */ "@/views/Common/404"),
  },
  {
    name: "login",
    meta: {
      title: "登录",
    },
    path: "/Login",
    component: () => import(/* webpackChunkName: "group-other" */ "@/views/Common/Login"),
    props: (route) => ({
      returnUrl: route.query.returnUrl,
    }),
  },
  {
    name: "login2",
    meta: {
      title: "账号密码登录",
      noNeedAuthorize: true,
    },
    path: "/Login2",
    component: () => import(/* webpackChunkName: "group-other" */ "@/views/Common/LoginCopy"),
    props: (route) => ({
      returnUrl: route.query.returnUrl,
    }),
  },
  ...AuthRoutes,
  ...MobileRoutes,
  // {
  //   name: "error",
  //   path: "/error",
  //   meta: {
  //     title: "错误",
  //   },
  //   component: () => import(/* webpackChunkName: "group-other" */ "@/views/Common/Error"),
  //   props: (route) => ({
  //     msg: decodeURI(route.query.msg || ""),
  //     detail: decodeURI(route.query.detail || ""),
  //     type: "error",
  //   }),
  // },
  // {
  //   name: "success",
  //   path: "/success",
  //   meta: {
  //     title: "成功",
  //   },
  //   component: () => import(/* webpackChunkName: "group-other" */ "@/views/Common/Error"),
  //   props: (route) => ({
  //     msg: decodeURI(route.query.msg || ""),
  //     detail: decodeURI(route.query.detail || ""),
  //     type: "success",
  //   }),
  // },
];
export default config;
