export default [
  {
    path: "/Register",
    meta: {
      title: "注册",
      name: "Register",
      noNeedAuthorize: true,
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Common/Login/Register/index.vue"),
  },
  {
    path: "/Register/init",
    meta: {
      title: "企业注册",
      name: "RegisterInit",
      noNeedAuthorize: true,
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Common/Login/Register/init.vue"),
  },
];
