<!--  -->
<template>
  <div class="nav-list">
    <v-list dense>
      <v-list-item-group v-model="active" color="primary">
        <v-list-item v-for="(item, i) in items" :key="i" :value="item.name" :to="item.to">
          <v-list-item-icon>
            <i :class="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "NavList",
  props: {
    activePage: { type: String },
    items: { type: Array },
  },
  data() {
    return {
      active: "",
    };
  },
  watch: {
    activePage(val) {
      this.active = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-list {
  width: 220px;
  border-right: 1px solid #f1f1f1;
  padding: 10px 15px;
  flex-shrink: 0;
  @media screen and (max-width: 900px) {
    display: none;
  }
  .v-list-item {
    i {
      line-height: 24px;
      font-size: 16px;
    }
    &::before {
      border-radius: 4px;
    }
  }
}
</style>
